import React from "react";
const CouponIndex = () => {
    let navigate = React.Router.useNavigate();
    const [business, setBusiness] = React.useState(
        React.cookie.load("business") ? React.cookie.load("business") : {}
    );
    const [couponlist, setcouponlist] = React.useState([])
    React.useEffect(() => {
        getCouponListData()
    }, [])

    const getCouponListData = async () => {
        let result = await React.Api.couponBusiness({
            busid: business.id ?? 0
        });

        if (result && result.code == 1) {
            setcouponlist(result.data)
        }
    }

    return (
        <>
            <link rel="stylesheet" href="/assets/css/coupon.css" />
            <React.UI.NavBar
                title="优惠券"
                leftText=""
                onClickLeft={() => navigate('/business/index')}
            />

            <div className="coupon_list">
                {
                    couponlist.map((item) => {
                        return (
                            <div className="coupon_item" key={item.id}>
                                <div className="item_content">
                                    <React.Router.NavLink to={`/coupon/info?cid=${item.cid}`} >
                                        <div className="left">
                                            {parseFloat(item.coupon.rate) * 100}<span>折</span>
                                        </div>
                                        <div className="right">
                                            <div>{item.coupon.title}</div>
                                            <div>开始时间：{item.coupon.createtime_text}</div>
                                            <div>结束时间：{item.coupon.endtime_text}</div>
                                            <div>领取仅限量 {item.coupon.total} 张，赶快领取！</div>
                                        </div>
                                    </React.Router.NavLink>
                                </div>
                                <div className="item_btn">
                                    {
                                        item.status == '1' && <React.UI.Button type='info' >未使用</React.UI.Button>
                                    }
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </>
    )
}

export default CouponIndex;