import { POST, } from "@/services/request.js";
//导出接口
const base = {
    guestIndex(data = {}) {
        return POST({
            url: "/guest/index",
            params: data,
        })
    },
    guestAdd(data = {}) {
        return POST({
            url: "/guest/add",
            params: data,
        })
    },
    guestDel(data = {}) {
        return POST({
            url: "/guest/del",
            params: data,
        })
    },
    guestInfo(data = {}) {
        return POST({
            url: "/guest/info",
            params: data,
        })
    },
    guestEdit(data = {}) {
        return POST({
            url: "/guest/edit",
            params: data,
        })
    },
    guestOrder(data = {}) {
        return POST({
            url: "/guest/order",
            params: data,
        })
    }
}
export default base;
