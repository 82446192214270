import React, { useState } from 'react';
import { Map, APILoader } from '@uiw/react-baidu-map';
const InfolVerification = () => {
    let navigate = React.Router.useNavigate();
    const [keywords, setKeywords] = React.useState('');
    const [hotellist, sethotellist] = React.useState([]);
    const [list, setlist] = React.useState({});
    React.useEffect(() => {
        gethotel();
    }, [keywords]);
    const gethotel=async ()=>{
        let result = await React.Api.hotelsIndex();
        if (result && result.code == 1) {
            sethotellist(result.data.roomlist);
            setlist(result.data)
        }
    }
return (
    <>
    <link rel="stylesheet" href="/assets/css/hotel-info.css" />
    {/* <!-- 导航 --> */}
    <React.UI.NavBar
                title="门店详情"
                onClickLeft={() => navigate("/business/index")}
            />
     {/* 轮播图 */}
     <div className="swiper mySwiper">
     <React.UI.Swiper>
                {hotellist.map((item) => (
                    <React.UI.Swiper.Item key={item.id}>
                        <React.Router.NavLink to={`/room/info?cid=${item.id}`} style={{ width: "100%" }}>
                            <React.UI.Image
                                lazyload
                                src={item.thumb_text}
                                width="100%"
                                height="230"
                                fit="cover"
                            />
                        </React.Router.NavLink>
                    </React.UI.Swiper.Item>
                ))}
            </React.UI.Swiper>
     </div>
    <div className="hotelinfo">
    <div className="hotelinfo_title">
                    创想第一门店
                </div>
        <div className="map">
                    <APILoader akay="BhI8joPPFX8zDlFGtvHllfFR7lOigChD">
                        <Map center={{ lng: 113.3265410, lat: 23.1015070 }} zoom={15}/>
                    </APILoader>
                </div>
        <div className="hotelinfo_desc">
            <div className="desc_title">简介</div>
            <div>{list.content}</div>
        </div>
        <div className="hotelinfo_desc">
            <div className="desc_title">详情</div>
            <div>{list.info}</div>
        </div>
    </div>
    </>
)

}
export default InfolVerification;





