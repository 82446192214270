
import React from "react";
const OrderRate = () => {
    let navigate = React.Router.useNavigate();
    let [searchParams] = React.Router.useSearchParams();
    const [business, setBusiness] = React.useState(
        React.cookie.load("business") ? React.cookie.load("business") : {}
    );
    const [orderid, setOrderid] = React.useState(searchParams.get('orderid') ?? 0)
    const [info, setInfo] = React.useState({ room: { flag_text: [] }, })
    const [guestlist, setGuestlist] = React.useState([])
    const [form] = React.UI.Form.useForm()

    React.useEffect(() => {
        getOrderInfoData()
    }, [orderid])

    const getOrderInfoData = async () => {
        let result = await React.Api.orderInfo({
            busid: business.id,
            orderid: orderid
        });

        if (result && result.code == 1) {
            setInfo(result.data.info)
            setGuestlist(result.data.guestlist)
        }
    }


    const onFinish = async (values) => {
        let result = await React.Api.orderRate({
            busid: business.id,
            orderid: orderid,
            rate: values.rate,
            comment: values.comment
        });
        if (result.code == 1) {
            React.success(result.msg, () => navigate("/order/index"));
        } else {
            React.error(result.msg);
        }
    }

    return (
        <>
            <link rel="stylesheet" href="/assets/css/order_info.css" />
            <React.UI.NavBar
                title="订单详情"
                leftText=""
                onClickLeft={() => navigate('/order/index')}
            />

            <div className="order_info">
                <div className="info_content">
                    <div className="content_title">订单信息：</div>
                    <div className="content_item">
                        <p>入住时间</p>
                        <div>{info.starttime_text} {info.startday_text} - {info.endtime_text} {info.endday_text} </div>
                    </div>
                    <div className="content_item">
                        <p>入住天数</p>
                        <div>共 {info.order_day} 晚</div>
                    </div>
                    <div className="content_item">
                        <p>优惠价格</p>
                        <div className="content_price">￥{info.price}</div>
                    </div>
                    <div className="content_item">
                        <p>订单原价</p>
                        <div className="content_price"><s>￥{info.origin_price}</s></div>
                    </div>
                    <div className="content_item">
                        <p>房间单价</p>
                        <div className="content_price">￥{info.room.price}</div>
                    </div>
                    <div className="content_item">
                        <p>支付方式</p>
                        <div>{info.type_text}</div>
                    </div>
                    <div className="content_item">
                        <p>入住人数</p>
                        <div>{guestlist.length}人</div>
                    </div>
                    <div className="content_item">
                        <p>订单备注</p>
                        <div>{info.remark}</div>
                    </div>
                </div>
                <div className="info_footer">
                    <div className="content_title">房屋信息：</div>
                    <div className="detail">
                        <div className="thumb">
                            <img src={info.room.thumb_text} alt="" />
                        </div>
                        <div className="right">
                            <p>{info.room.name}</p>
                            <div className="tips">
                                {
                                    info.room.flag_text.map((flag, index) => {
                                        return (
                                            <React.UI.Tag type="success" key={index} style={{ marginRight: '10px' }}>{flag}</React.UI.Tag>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- 入住信息 --> */}
                <div className="info_footer">
                    <div className="content_title">入住信息：</div>
                    <div className="resident_list">
                        <div className="list-body">
                            <div className="list-body-inner">
                                {
                                    guestlist.map((item) => {
                                        return (
                                            <div className="list-item" key={item.id}>
                                                <div className="list-item-content">
                                                    <div className="list-item-content-main">
                                                        姓名：{item.nickname}
                                                        <div className="list-item-description">手机号码：{item.mobile}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="info_footer">
                    <div className="content_title">评价信息：</div>
                    <React.UI.Form
                        form={form}
                        onFinish={onFinish}
                        footer={
                            <div style={{ margin: '16px 16px 0' }}>
                                <React.UI.Button round nativeType='submit' color='linear-gradient(to right, #ff6034, #ee0a24)' block>
                                    提交
                                </React.UI.Button>
                            </div>
                        }
                    >
                        <React.UI.Form.Item
                            rules={[{ required: true, message: '请填写用户名' }]}
                            name='rate'
                            label=''
                        >
                            <React.UI.Rate icon={<React.ICON.Fire />} voidIcon={<React.ICON.FireO />} />
                        </React.UI.Form.Item>
                        <React.UI.Form.Item
                            name='comment'
                            label=''
                        >
                            <React.UI.Input.TextArea placeholder="请输入评价内容" maxLength={120} showWordLimit />
                        </React.UI.Form.Item>
                    </React.UI.Form>
                </div>
            </div>
        </>
    )
}

export default OrderRate;