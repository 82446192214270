import React from "react";
import Footer from "@/components/footer";
import { Toast } from 'react-vant';
const BusinessIndex = () => {
    let navigate = React.Router.useNavigate();
    
    const [business, setBusiness] = React.useState(
        React.cookie.load("business") ? React.cookie.load("business") : {}
    );
    const LogoutEvent = () => {
        React.UI.Dialog.confirm({
            message: "确认是否退出登陆?",
            onConfirm: () => {
                // 清除用户存储的数据
                React.cookie.remove("business");
                setBusiness({});
                navigate("/business/login");
            },
        }).catch(() => { });
    };
    const handleClick = (e) => {
        if (business.auth==1) {
          e.preventDefault(); // 阻止默认跳转
          Toast.info('邮箱已认证，无需再次认证');
          navigate('/business/index'); // 跳转回首页
          return;
        }
        // 如果未认证，会正常跳转到 /business/email
      };
    return (
        <>
            <link rel="stylesheet" href="/assets/css/user.css" />
            <div className="top"></div>
            <div className="header">
                <div className="userinfo">
                    <div className="avatar">
                        <img src={business.avatar} alt="" />
                    </div>
                    <div className="nickname">{business.nickname}</div>
                    <div className="nickname content"></div>
                </div>
                <div className="corrugated">
                    <div className="wave-top wave-item"></div>
                    <div className="wave-middle wave-item"></div>
                    <div className="wave-bottom wave-item"></div>
                </div>
            </div>

            <div className="menu-cell">
                <div className="item">
                    <React.Router.NavLink to="/business/profile">
                        <div className="title">个人资料</div>
                        <div className="icon">
                            <img src="/assets/images/right.png" alt="" />
                        </div>
                    </React.Router.NavLink>
                </div>
                <div className="item">
                    <React.Router.NavLink  to="/business/email" onClick={handleClick}>
                        <div className="title">邮箱认证</div>
                        <div className="icon">
                            <img src="/assets/images/right.png" alt="" />
                        </div>
                    </React.Router.NavLink>
                </div>
                <div className="item">
                    <React.Router.NavLink to="/guest/index">
                        <div className="title">住客信息</div>
                        <div className="icon">
                            <img src="/assets/images/right.png" alt="" />
                        </div>
                    </React.Router.NavLink>
                </div>
                <div className="item">
                    <React.Router.NavLink to="/order/index">
                        <div className="title">房间订单</div>
                        <div className="icon">
                            <img src="/assets/images/right.png" alt="" />
                        </div>
                    </React.Router.NavLink>
                </div>
                <div className="item">
                    <React.Router.NavLink to="/coupon/index">
                        <div className="title">我的优惠券</div>
                        <div className="icon">
                            <img src="/assets/images/right.png" alt="" />
                        </div>
                    </React.Router.NavLink>
                </div>
                <div className="item">
                    <React.Router.NavLink to="/business/info">
                        <div className="title">关于我们</div>
                        <div className="icon">
                            <img src="/assets/images/right.png" alt="" />
                        </div>
                    </React.Router.NavLink>
                </div>
                <div className="item">
                    <a onClick={LogoutEvent}>
                        <div className="title">退出登陆</div>
                        <div className="icon">
                            <img src="/assets/images/right.png" alt="" />
                        </div>
                    </a>
                </div>
            </div>

            <Footer />
        </>
    );
};

export default BusinessIndex;
