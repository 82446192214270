import React from "react";
import { areaList } from '@vant/area-data'
const Profile = () => {
    let navigate = React.Router.useNavigate();
    const [business, setBusiness] = React.useState(
        React.cookie.load("business") ? React.cookie.load("business") : {}
    );
    const [showArea, setShowArea] = React.useState(false)
    const [form] = React.UI.Form.useForm()
    const [region, setRegion] = React.useState(business.region ? business.region : '')

    const onFinish = async (values) => {
       
        let data = {
            id: business.id,
            nickname: values.nickname,
            email: values.email,
            gender: values.gender,
            province: business.province,
            city: business.city,
            district: business.district
        }

        // 判断是否修改密码
        if(values.password)
        {
            data.password = values.password
        }

        // 判断是否修改头像
        if(values.avatar.length > 0 && values.avatar[0].file)
        {
            data.avatar = values.avatar[0].file
        }
        let result = await React.Api.profile(data);
        if (result && result.code == 1) {
            React.cookie.save('business', result.data)
            React.success(result.msg, () => navigate("/business/index"));
        } else {
            React.error(result.msg);
        }
    }

    const ChangeArea = (val, options) => {
        setShowArea(false)
        let [province, city, district] = options
        let regions = ''
        if (province) {
            business.province = province.value
            regions = province.text + '-'
        }
        if (city) {
            business.city = city.value
            regions += city.text + '-'
        }
        if (district) {
            business.district = district.value
            regions += district.text
        }
        setRegion(regions)
    }

    return (
        <>
            <React.UI.NavBar
                title="基本资料"
                leftText=""
                onClickLeft={() => navigate(-1)}
            />

            {/* 表单 */}
            <React.UI.Form
                form={form}
                onFinish={onFinish}
                footer={
                    <div style={{ margin: '16px 16px 0' }}>
                        <React.UI.Button round nativeType='submit' type='info' block>
                            提交
                        </React.UI.Button>
                    </div>
                }
            >
                <React.UI.Form.Item
                    rules={[{ required: true, message: '请填写用户名' }]}
                    name='nickname'
                    label='昵称'
                    initialValue={business.nickname}
                >
                    <React.UI.Input placeholder='请输入用户名' />
                </React.UI.Form.Item>
                <React.UI.Form.Item
                    name='mobile'
                    label='手机号码'
                    initialValue={business.mobile}
                >
                    <React.UI.Input placeholder='请输入手机号码' readOnly />
                </React.UI.Form.Item>
                <React.UI.Form.Item
                    name='password'
                    label='密码'
                >
                    <React.UI.Input placeholder='请输入密码' type="password"/>
                </React.UI.Form.Item>
                <React.UI.Form.Item
                    rules={[{ required: true, message: '请填写正确的邮箱' }]}
                    name='email'
                    label='邮箱'
                    initialValue={business.email}
                >
                    <React.UI.Input placeholder='请输入邮箱' />
                </React.UI.Form.Item>
                <React.UI.Form.Item name='gender' label='性别' rules={[{ required: true, message: '请选择性别' }]} initialValue={business.gender}>
                    <React.UI.Radio.Group direction='horizontal'>
                        <React.UI.Radio name='0'>保密</React.UI.Radio>
                        <React.UI.Radio name='1'>男</React.UI.Radio>
                        <React.UI.Radio name='2'>女</React.UI.Radio>
                    </React.UI.Radio.Group>
                </React.UI.Form.Item>
                {/* 地区 */}
                <React.UI.Form.Item
                    isLink
                    label='城市选择'
                    trigger='onConfirm'
                    onClick={() => setShowArea(true)}
                >
                    <React.UI.Input placeholder='请选择地区' readOnly value={region} />
                </React.UI.Form.Item>
                {/* 弹出层 */}
                <React.UI.Popup
                    visible={showArea}
                    style={{ height: '30%' }}
                    position='bottom'
                    onClose={() => setShowArea(false)}
                >
                    {/* 省市区组件 */}
                    <React.UI.Area title='地区选择' areaList={areaList} onConfirm={ChangeArea} onCancel={() => setShowArea(false)} />
                </React.UI.Popup>

                {/* 头像 */}
                <React.UI.Form.Item
                    name='avatar'
                    label='头像'
                    initialValue={[
                        {
                            url: business.avatar,
                        },
                    ]}
                >
                    <React.UI.Uploader maxCount={1} />
                </React.UI.Form.Item>
            </React.UI.Form>
        </>
    )
}

export default Profile;