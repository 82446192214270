import React from "react";
import Footer from "@/components/footer";
const Home = () => {
    const [couponlist, setcouponlist] = React.useState([]);
    const [list, setList] = React.useState([])
    const [finished, setFinished] = React.useState(false);
    const [page, setPage] = React.useState(1);
    const [keywords, setKeywords] = React.useState('');
    React.useEffect(() => {
        getCouponData();
        onRefresh()
    }, [keywords]);

    const getCouponData = async () => {
        let result = await React.Api.couponIndex();
        if (result && result.code == 1) {
            setcouponlist(result.data);
        }
    };

    const onRefresh = () => {
        setFinished(false);
        setPage(1);
        setList([]);
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve(true);
            }, 1000);
        });
    };

    const onLoadRefresh = () => {
        setFinished(true);
        getHomeData();
    };

    const getHomeData = async () => {
        let result = await React.Api.hotelIndex({
            page: page,
            keywords: keywords,
        });

        if (result) {
            if (result.code == 0) {
                setFinished(true);
                return false;
            } else {
                setPage(page + 1)
                setList(list.concat(result.data))
                setTimeout(() => {
                    setFinished(false);
                }, 2000);
            }
        }
    };


    return (
        <>
            {/* 引入单独页面样式 */}
            <link rel="stylesheet" href="/assets/css/index.css" />
            <div className="top">
                <div>首页</div>
            </div>

            {/* 轮播图 */}
            <React.UI.Swiper>
                {couponlist.map((item) => (
                    <React.UI.Swiper.Item key={item.id}>
                        <React.Router.NavLink to={`/coupon/info?cid=${item.id}`} style={{ width: "100%" }}>
                            <React.UI.Image
                                lazyload
                                src={item.thumb_text}
                                width="100%"
                                height="213"
                                fit="cover"
                            />
                        </React.Router.NavLink>
                    </React.UI.Swiper.Item>
                ))}
            </React.UI.Swiper>

            <div className="hotel_search">
                <React.UI.Input value={keywords} onChange={setKeywords} clearable placeholder="请输入关键词搜" />
                <div className="screen_icon">
                    <img src="./assets/images/screen.png" alt="" />
                </div>
            </div>

            <div className="hotellist">
                <React.UI.PullRefresh
                    successText="刷新成功"
                    onRefresh={() => onRefresh(true)}
                >
                    <React.UI.List finished={finished} onLoad={onLoadRefresh}>
                        {
                            list.map((item) => {
                                return (
                                    <div className="item" key={item.id}>
                                        <React.Router.NavLink to={`/room/info?roomid=${item.id}`} >
                                            <div className="images">
                                                <div className="swipers">
                                                    <img src={item.thumb_text} alt="" />
                                                </div>
                                                <div className="title">{item.name}</div>
                                            </div>
                                            <div>
                                                <div className="item_top">
                                                    {
                                                        item.flag_text.map((flag, index) => {
                                                            return (
                                                                <span key={index}>{flag}</span>
                                                            )
                                                        })
                                                    }
                                                </div>
                                                <div className="item_bot">
                                                    <div className="font">
                                                        <span className="price">￥{item.price}/晚</span>
                                                    </div>
                                                    <div className="btn">立即预定</div>
                                                </div>
                                            </div>
                                        </React.Router.NavLink>
                                    </div>
                                )
                            })

                        }

                    </React.UI.List>
                </React.UI.PullRefresh>
            </div>

            <div className="hotel_more">没有更多了</div>

            <div style={{ height: "55px" }}></div>

            <Footer />
        </>
    );
};

export default Home;
