import React from "react";
const RoomConfirm = () => {
    let navigate = React.Router.useNavigate();
    let [searchParams] = React.Router.useSearchParams();
    const [business, setBusiness] = React.useState(
        React.cookie.load("business") ? React.cookie.load("business") : {}
    );
    const [roomid, setRoomid] = React.useState(searchParams.get('roomid') ?? 0)
    const [info, setInfo] = React.useState({ thumb_list: [], flag_text: [], price: 0 })

    //日期
    let [DateShow, setDateShow] = React.useState(false)
    let [datestr, setdatestr] = React.useState('')
    let [starttime, setstarttime] = React.useState(0)
    let [endtime, setendtime] = React.useState(0)

    // 住客信息
    let [guestlist, setGuestlist] = React.useState([])
    let [GuestShow, setGuestShow] = React.useState(false)
    let [guestName, setGuestName] = React.useState('请选择住客信息')
    let [guestCheck, setGuestCheck] = React.useState([])

    // 优惠券
    let [couponShow, setcouponShow] = React.useState(false)
    let [couponlist, setcouponlist] = React.useState([])
    let [couponName, setcouponName] = React.useState('')
    let [couponid, setcouponid] = React.useState('')

    // 支付方式
    let [payShow, setpayShow] = React.useState(false)
    let [paylist, setPaylist] = React.useState([
        {
            text: '余额',
            value: 'money',
        },
        {
            text: '微信',
            value: 'wx',
        },
        {
            text: '支付宝',
            value: 'zfb',
        }
    ])
    let [paystr, setpaystr] = React.useState('')
    let [pay, setPay] = React.useState('')

    // 备注信息
    let [remark, setRemark] = React.useState()
    // 天数
    let [day, SetDay] = React.useState(0)
    // 优惠价
    let [price, SetPrice] = React.useState(0)
    // 原价
    let [OriginPrice, SetOriginPrice] = React.useState(0)

    React.useEffect(() => {
        getRoomInfoData();
        getGuestData();
        getCouponData();
    }, [roomid])

    const getGuestData = async () => {
        let result = await React.Api.guestOrder({
            busid: business.id
        });

        if (result && result.code == 1) {
            setGuestlist(result.data)
        }
    }

    const getCouponData = async () => {
        let result = await React.Api.couponBusiness({
            busid: business.id
        });

        if (result && result.code == 1) {
            let data = []
            result.data.map((item) => {
                data.push({
                    text: item.coupon.title + '-' + parseFloat(item.coupon.rate) * 100 + '折',
                    value: item.id,
                    rate: item.coupon.rate
                })
            })
            setcouponlist(data)
        }
    }

    const getRoomInfoData = async () => {
        let result = await React.Api.hotelInfo({
            roomid: roomid
        });

        if (result && result.code == 1) {
            setInfo(result.data.info)
        }
    }

    React.useEffect(() => {
        Total()
    }, [datestr, couponName])

    // 计算价格
    const Total = () => {
        //时间差
        let day = endtime - starttime;
        day = day / 86400
        day = parseInt(day)
        
        // 价格
        var origin_price = day * parseFloat(info.price)
        var price = origin_price

        // 使用优惠券
        if (couponid) {
            var rate = 1;
            couponlist.map((item) => {
                if (item.value == couponid) {
                    rate = item.rate
                }
            })
            //计算折扣后的价格
            price = origin_price * parseFloat(rate)
        }

        SetDay(day)
        SetPrice(price.toFixed(2))
        SetOriginPrice(origin_price)
    }


    // 入住时间
    const DateConfirm = (val) => {

        let datestr = `${new Date(val[0]).toLocaleDateString()} - ${new Date(val[1]).toLocaleDateString()}`
        //获取到开始时间和结束时间
        //标准时间转换为时间戳
        let starttime = Date.parse(new Date(val[0]).toLocaleDateString()) / 1000
        let endtime = Date.parse(new Date(val[1]).toLocaleDateString()) / 1000

        setdatestr(datestr)
        setstarttime(starttime)
        setendtime(endtime)
    }

    // 住客信息
    const ChangeGuest = (id) => {
        const newValue = guestCheck.includes(id)
            ? guestCheck.filter(el => el !== id)
            : [...guestCheck, id]
        setGuestCheck(newValue)

        let nickname = ''
        guestlist.map((item) => {
            if (newValue.includes(item.id)) {
                nickname += item.nickname + '、'
            }
        })
        setGuestName(nickname.substring(0, nickname.length - 1));
    }

    // 优惠券
    const CouponConfirm = (val, item) => {
        setcouponName(item.text)
        setcouponid(item.value)
    }

    // 支付方式
    const PayChange = (val, item) => {
        setpaystr(item.text)
        setPay(item.value)
    }

    // 提交订单
    const OnSubmits = async() =>{
        if (day <= 0) {
            React.error ('请选择入住日期');
            return false;
        }

        if (guestCheck.length <= 0) {
            React.error ('请选择住客信息');
            return false;
        }

        if(!paystr)
        {
            React.error ('请选择支付方式');
            return false;
        }

        let data = {
            busid: business.id,
            roomid: roomid,
            guest: guestCheck.toString(),
            origin_price: OriginPrice,
            price: price,
            starttime: starttime,
            endtime: endtime,
            couponid: couponid,
            remark: remark,
            type: pay
        }

        let result = await React.Api.orderAdd(data);
        if (result && result.code == 1) {
            React.success(result.msg, () => navigate("/order/index"));
        } else {
            React.error(result.msg);
        }
    }

    return (
        <>
            <link rel="stylesheet" href="/assets/css/confirm.css" />

            <div className="skeleton">
                <div className="detail">
                    <div className="thumb">
                        <img src={info.thumb_text} alt="" />
                    </div>
                    <div className="right">
                        <p>{info.name}</p>
                        <p>￥{info.price} /晚</p>
                        <div className="tips">
                            {
                                info.flag_text.map((flag, index) => {
                                    return (
                                        <span key={index}>{flag}</span>
                                    )
                                })
                            }
                            {/* <span>新房特惠</span>
                            <span>10.00㎡</span>
                            <span>宜住1人</span> */}
                        </div>
                    </div>
                </div>
                <div className="skeleton_rect">
                    <div className="item">
                        <label>入住日期</label>
                        {/* 入住日期 */}
                        <React.UI.Input placeholder='请选择入住日期' className="item_right" readOnly value={datestr} onClick={() => setDateShow(true)} />

                        {/* 日期选择 */}
                        <React.UI.Calendar showConfirm={false} type='range' visible={DateShow} onClose={() => setDateShow(false)} onConfirm={DateConfirm} />
                    </div>
                    <div className="item">
                        <label>住客信息</label>
                        <div className="item_right" onClick={() => setGuestShow(true)}>
                            {guestName}
                        </div>
                        <React.UI.Button type='primary' size='small' round onClick={() => navigate('/guest/add')}>添加/选择</React.UI.Button>

                        {/* 住客信息 */}
                        <React.UI.Popup
                            visible={GuestShow}
                            style={{ height: '30%' }}
                            position='bottom'
                            onClose={() => setGuestShow(false)}
                        >
                            <React.UI.Checkbox.Group value={guestCheck} onChange={setGuestCheck}>
                                <React.UI.Cell.Group>
                                    {
                                        guestlist.map((item) => {
                                            return (
                                                <React.UI.Cell
                                                    key={item.id}
                                                    clickable
                                                    title={item.nickname}
                                                    onClick={() => ChangeGuest(item.id)}
                                                    rightIcon={<React.UI.Checkbox name={item.id} />}
                                                />
                                            )
                                        })
                                    }
                                </React.UI.Cell.Group>
                            </React.UI.Checkbox.Group>
                        </React.UI.Popup>
                    </div>
                </div>
                <div className="skeleton_rect">
                    <div className="item">
                        <label>优惠券</label>
                        <div className="item_right">
                            {couponName}
                        </div>
                        <React.UI.Picker
                            popup={{
                                round: true,
                            }}
                            value={couponShow}
                            onClose={() => setcouponShow(false)}
                            columns={couponlist}
                            placeholder=''
                            onConfirm={CouponConfirm}
                        >
                            {(val: string, _, actions) => {
                                return (
                                    <React.UI.Button type='primary' size='small' round onClick={() => actions.open()}>选择</React.UI.Button>
                                )
                            }}
                        </React.UI.Picker>
                    </div>
                </div>
                <div className="skeleton_rect">
                    <div className="item">
                        <label>支付方式</label>
                        <div className="item_right">
                            {paystr}
                        </div>
                        <React.UI.Picker
                            popup={{
                                round: true,
                            }}
                            value={payShow}
                            onClose={() => setpayShow(false)}
                            columns={paylist}
                            placeholder=''
                            onConfirm={PayChange}
                        >
                            {(val: string, _, actions) => {
                                return (
                                    <React.UI.Button type='primary' size='small' round onClick={() => actions.open()}>选择</React.UI.Button>
                                )
                            }}
                        </React.UI.Picker>
                    </div>
                </div>
                <div className="skeleton_rect">
                    <div className="item">
                        <label>订单备注</label>
                        <div className="item_name">
                            <React.UI.Input.TextArea placeholder="请输入订单备注" maxLength={50} showWordLimit value={remark} onChange={setRemark} />
                        </div>
                    </div>
                </div>
                <div className="skeleton_price">
                    <div className="tips">房间费用</div>
                    <div className="prices">
                        <span>￥{OriginPrice}</span>
                        <span>共 {day} 晚</span>
                    </div>
                </div>
            </div>
            <div className="comfirm_foot-bar">
                {
                    price == OriginPrice ?
                        <div className="text">
                            <span>总价:</span>
                            <span>￥{price}</span>
                        </div> :
                        <div className="text">
                            <span>优惠价格:</span>
                            <span>￥{price}</span>
                            <s> 原价：￥{OriginPrice}</s>
                        </div>
                }
                <div className="btns">
                    <React.UI.Button type='primary' size='small' round onClick={OnSubmits}>提交订单</React.UI.Button>
                </div>
            </div>
        </>
    )
}
export default RoomConfirm;