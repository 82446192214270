import React from "react";
import { TMap } from '@map-component/react-tmap';
const RoomInfo = () => {
    let navigate = React.Router.useNavigate();
    let [searchParams] = React.Router.useSearchParams();
    const [roomid, setRoomid] = React.useState(searchParams.get('roomid') ?? 0)
    const [info, setInfo] = React.useState({ thumb_list: [], flag_text: [] })
    const [business, setBusiness] = React.useState(
        React.cookie.load("business") ? React.cookie.load("business") : {}
    );
    const [commentlist, setcommentlist] = React.useState([])

    React.useEffect(() => {
        getRoomInfoData()
    }, [roomid])

    const getRoomInfoData = async () => {
        let result = await React.Api.hotelInfo({
            roomid: roomid
        });

        if (result && result.code == 1) {
            setInfo(result.data.info)
            setcommentlist(result.data.commentlist)
        }
    }

    const toPages = () => {
        if (JSON.stringify(business) == '{}') {
            React.success('未登陆，请先去登陆', () => navigate("/business/login"));
            return false;
        }
        navigate(`/room/confirm?roomid=${roomid}`)
    }

    return (
        <>
            <link rel="stylesheet" href="/assets/css/detail.css" />

            <React.UI.NavBar
                title="酒店房间详情"
                leftText=""
                onClickLeft={() => navigate("/")}
            />

            <React.UI.Swiper>
                {info.thumb_list.map((item, index) => (
                    <React.UI.Swiper.Item key={index}>
                        <React.UI.Image
                            lazyload
                            src={item}
                            width="100%"
                            height="213"
                            fit="cover"
                        />
                    </React.UI.Swiper.Item>
                ))}
            </React.UI.Swiper>

            <div className="detail_top">
                <div id="intro" className="intro">
                    <div className="title">{info.name}</div>
                    <div className="betwee">
                        <div className="left">
                            {
                                info.flag_text.map((flag, index) => {
                                    return (
                                        <span key={index}>{flag}</span>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
                {/* 简介 */}
                <div className="notesin">
                    <div className="title">简介简介</div>
                    <div className="item">
                        {info.content}
                    </div>
                </div>
                {/* 预订需知 */}
                <div className="notesin">
                    <div className="title">预订须知</div>
                    <div className="item">
                        <span className="tips">预订房型：</span>
                        <span>{info.name}</span>
                    </div>
                    <div className="item">
                        <span className="tips">入离时间：</span>
                        <span>15:00 后入住，12:00 前退房</span>
                    </div>
                    <div className="item">
                        <span className="tips">剩余房间数量：</span>
                        <span>{info.total}</span>
                    </div>
                </div>

                {/* 地图 */}
                <div className="notesin">
                    <div className="title">地图路线</div>
                    {/* center： 地图中心点经纬度 version：sdk 版本 */}
                    <TMap
                        mapKey="ZPMBZ-BWI3M-PP56L-66XNR-E7JYV-44B72"
                        center={{ lat: 23.103578, lng: 113.314681 }} // 设置中心点坐标
                        version='1.exp'
                    />
                </div>

                {/* 评价 */}
                <div id="comment" className="comment">
                    <div className="title">评价</div>
                    <div className="rate-list-item">
                        {
                            commentlist.map((item) => {
                                return (
                                    <div className="item-content" key={item.id}>
                                        <div className="content-prefix">
                                            <div className="ratelist">
                                                <div className="image">
                                                    <img src={item.business.avatar_text} alt="" />
                                                </div>
                                                <p className="nickname">张三</p>
                                            </div>
                                        </div>
                                        <div className="content-main">
                                            <React.UI.Rate icon={<React.ICON.Fire />} voidIcon={<React.ICON.FireO />} value={item.rate} readOnly />
                                            <div className="item-description">
                                                <React.UI.Typography.Text ellipsis={{
                                                    rows: 2,
                                                    collapseText: '收起',
                                                    expandText: '展开',
                                                }}>{item.comment}</React.UI.Typography.Text>
                                                <p className="item-time">{item.comtime_text}</p>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                    <div className="divider-horizontal">
                        <div className="divider-content">
                            <a href="hotel-rate.html">更多评价内容</a>
                        </div>
                    </div>
                </div>
            </div>

            <div className="foot-bar">
                <div className="price">
                    ￥{info.price}
                </div>
                <div className="">
                    <React.UI.Button type='primary' size='normal' block round onClick={toPages}>立即预定</React.UI.Button>
                </div>
            </div>
        </>
    )
}
export default RoomInfo;