import React from "react";
import OrderIndex from "@/components/order/index";
import OrderInfo from '@/components/order/info'
import OrderRate from '@/components/order/rate'

const RouterMap = [
    {
        path: "index",
        component: OrderIndex,
        auth: true
    },
    {
        path: "info",
        component: OrderInfo,
        auth: true
    },
    {
        path: "rate",
        component: OrderRate,
        auth: true
    }
];

//默认父组件 可以自己定义
const layout = () => {
    return (
        <>
            <React.Router.Outlet />
        </>
    );
};

const RouterList = [
    {
        path: "/order",
        component: layout,
        children: RouterMap,
    },
];

export default RouterList;