import React from 'react'
const CouponInfo = () => {
    let navigate = React.Router.useNavigate();
    let [searchParams] = React.Router.useSearchParams();
    const [cid, setCid] = React.useState(searchParams.get('cid') ?? 0)
    const [business, setBusiness] = React.useState(
        React.cookie.load("business") ? React.cookie.load("business") : {}
    );
    const [info, setInfo] = React.useState({ rate: '0' })
    const [receive, setReceive] = React.useState([])

    React.useEffect(() => {
        getCouponInfoData()
    }, [cid])

    const getCouponInfoData = async () => {
        let result = await React.Api.couponInfo({
            busid: business.id ?? 0,
            cid: cid
        });

        if (result && result.code == 1) {
            setInfo(result.data.info)
            setReceive(result.data.receive)
        }
    }

    const ChangeCoupon = async () => {
        if (JSON.stringify(business) == '{}') {
            React.success('未登陆，请先去登陆', () => navigate("/business/login"));
            return false;
        }

        let result = await React.Api.couponReceive({
            busid: business.id ?? 0,
            cid: cid
        });
        if (result.code == 1) {
            React.success(result.msg);
            getCouponInfoData()
        } else {
            React.error(result.msg);
        }
    }

    return (
        <>
            <link rel="stylesheet" href="/assets/css/coupon-info.css" />

            <React.UI.NavBar
                title="优惠券详情"
                leftText=""
                onClickLeft={() => navigate(-1)}
            />
            <React.UI.Image fit="cover" width='100%' height='213px' src={info.thumb_text} />

            <div className="demo-notice-bar">
                <React.UI.NoticeBar leftIcon={<React.ICON.VolumeO />} color="#1677ff" background="#d0e4ff">
                    <React.UI.Swiper
                        autoplay={3000}
                        indicator={false}
                        vertical
                        className="notice-swipe"
                    >
                        {
                            receive.map((item) => {
                                return (
                                    <React.UI.Swiper.Item key={item.id}>用户：{item.business.nickname_text} 在 {item.createtime_text} 领取了优惠券</React.UI.Swiper.Item>
                                )
                            })
                        }
                    </React.UI.Swiper>
                </React.UI.NoticeBar>
            </div>

            <div className="coupon_detail">
                <div className="coupon_info">
                    <div className="left">
                        <div className="left_top">
                            <div>
                                <span>{parseFloat(info.rate) * 100}</span>折
                            </div>
                            <div className="top_info">
                                <div>优惠券</div>
                                <div>COUPON</div>
                            </div>
                        </div>
                    </div>
                    <div className="receive">
                        {
                            info.receive_text ? <React.UI.Button type='info' disabled >已领取</React.UI.Button> : <React.UI.Button type='info' onClick={ChangeCoupon}>未领取</React.UI.Button>
                        }
                    </div>
                </div>
                <div className="coupon_prompt">
                    <div className="prompt_title">温馨提示：</div>
                    <div><span>•</span>领取后 {info.createtime_text} 至 {info.endtime_text} 有效</div>
                    <div><span>•</span>仅限量{info.total}张，赶快领取！</div>
                </div>
            </div>
        </>
    )
}

export default CouponInfo;