import React from "react";
import Login from "@/components/business/login";
import Register from "@/components/business/register";
import BusinessIndex from "@/components/business/index";
import  Profile from '@/components/business/profile'
import  Email from '@/components/business/email'
import  Info from '@/components/business/info'
const RouterMap = [
    {
        path: "login",
        component: Login,
    },
    {
        path: "register",
        component: Register,
    },
    {
        path: "index",
        component: BusinessIndex,
        auth: true //校验用户登陆
    },
    {
        path: "profile",
        component: Profile,
        auth: true 
    }
    ,
    {
        path: "email",
        component: Email,
        auth: true 
    },
    {
        path: "info",
        component: Info,
        auth: true 
    },
    {
        path: "index",
        component: BusinessIndex,
        auth: true //校验用户登陆
    },
];

//默认父组件 可以自己定义
const layout = () => {
    return (
        <>
            <React.Router.Outlet />
        </>
    );
};

const RouterList = [
    {
        path: "/business", // 父路由
        component: layout,
        children: RouterMap,
    },
];

export default RouterList;
