import React from "react";

const AuthRouter = (props) => {

    const AuthLogin = () => {

        let navigate = React.Router.useNavigate();
        let business = React.cookie.load('business') ? React.cookie.load('business') : {}
        // 判断用户存储信息
        React.useEffect(() => {
            if (JSON.stringify(business) == '{}') {
                navigate('/business/login');
            } else {
                getCheck()
            }
        })
        // 接口校验
        const getCheck = async () => {
            let result = await await React.Api.check({
                id: business.id ?? 0,
                mobile: business.mobile ?? "",
            });
            if (result.code == 1) {
                React.cookie.save('business',result.data)
                return true;
            } else {
                React.cookie.remove('business')
                navigate('/business/login');
                return false;
            }
        }
        return false;
    }

    return (
        <>
            {
                props.auth && <AuthLogin />
            }
            {
                props.component
            }
        </>
    )
}

export default AuthRouter;