import React from "react";
const GuestEdit = () => {
    let navigate = React.Router.useNavigate();
    let [searchParams] = React.Router.useSearchParams();
    const [form] = React.UI.Form.useForm()
    const [business, setBusiness] = React.useState(
        React.cookie.load("business") ? React.cookie.load("business") : {}
    );
    const [guestid, setGuestid] = React.useState(searchParams.get('guestid') ?? 0)

    React.useEffect(() =>{
        getGuestInfoData()
    }, [guestid])

    const getGuestInfoData = async() =>{
        let result = await React.Api.guestInfo({
            busid: business.id,
            guestid: guestid
        });

        if(result && result.code == 1)
        {
            //可以使用 form.setFieldsValue 来动态改变表单值。
            form.setFieldsValue(result.data)
        }
    }

    const onFinish = async (values) => {
        values.busid = business.id
        values.guestid = guestid
        let result = await React.Api.guestEdit(values);
        if (result && result.code == 1) {
            React.success(result.msg, () => navigate("/guest/index"));
        } else {
            React.error(result.msg);
        }
    }
    return (
        <>
            <React.UI.NavBar
                title="编辑住客信息"
                leftText=""
                onClickLeft={() => navigate("/guest/index")}
            />

            {/* 表单 */}
            <React.UI.Form
                form={form}
                onFinish={onFinish}
                footer={
                    <div style={{ margin: '16px 16px 0' }}>
                        <React.UI.Button round nativeType='submit' type='info' block>
                            提交
                        </React.UI.Button>
                    </div>
                }
            >
                <React.UI.Form.Item
                    rules={[{ required: true, message: '请填写昵称' }]}
                    name='nickname'
                    label='昵称'
                >
                    <React.UI.Input placeholder='请输入昵称' />
                </React.UI.Form.Item>
                <React.UI.Form.Item
                    rules={[{ required: true, message: '请填写手机号码' }]}
                    name='mobile'
                    label='手机号码'
                >
                    <React.UI.Input placeholder='请输入手机号码' />
                </React.UI.Form.Item>
                <React.UI.Form.Item name='gender' label='性别' rules={[{ required: true, message: '请选择性别' }]}>
                    <React.UI.Radio.Group direction='horizontal'>
                        <React.UI.Radio name='0'>男</React.UI.Radio>
                        <React.UI.Radio name='1'>女</React.UI.Radio>
                    </React.UI.Radio.Group>
                </React.UI.Form.Item>
            </React.UI.Form>
        </>
    )
}

export default GuestEdit;