import React from "react";
import { useNavigate } from 'react-router-dom';
import { CountDown } from 'react-vant'; // 确保你导入了 CountDown 组件

const EmailVerification = () => {
    const navigate = useNavigate();
    const [business, setBusiness] = React.useState(
        React.cookie.load("business") ? React.cookie.load("business") : {}
    );
    const [code, setCode] = React.useState('');
    const [isShow, setIsShow] = React.useState(false);
    const countDownRef = React.useRef(); // 不需要指定类型
    const time = 10000; // 10秒

    const goBack = () => {
        navigate('/business/index');
    };

    const onFinish = () => {
        countDownRef.current.reset();
        setIsShow(false);
    };

    const send = async () => {
        setIsShow(true);
        countDownRef.current?.start();
        const result = await React.Api.sendemail({
            busid: business.id,
        });
        React.success(result.msg);
    };

    let onSubmit = async (values) => {
        let result = await React.Api.email({
            busid: business.id,
            code: code,
        });
        if (result.code === 1) {
            
            React.success(result.msg, () => {
                React.cookie.save('business',result.data)
                navigate("/business/index");
                // window.location.reload(); // 刷新页面
            });
            
        } else {
            React.error(result.msg);
        }
    };

    return (
        <>
            <React.UI.NavBar title="邮箱认证" left-arrow onClickLeft={goBack} />
            <React.UI.Form onFinish={onSubmit}>
                <React.UI.CellGroup inset>
                    <React.UI.Field
                        value={business.email}
                        label="邮箱"
                        placeholder="请输入邮箱帐号"
                        readonly
                    />
                    <React.UI.Field
                        value={code}
                        onChange={setCode}
                        label="邮箱验证码"
                        placeholder="请输入邮箱验证码"
                        button={
                            <React.UI.Button
                                size="small"
                                type="primary"
                                onClick={send}
                                disabled={isShow}
                            >
                                {!isShow ? '发送验证码' : (
                                    <CountDown
                                        ref={countDownRef}
                                        millisecond
                                        time={time}
                                        auto-start={false}
                                        format="ss秒"
                                        onFinish={onFinish}
                                    />
                                )}
                            </React.UI.Button>
                        }
                    >
                        
                    </React.UI.Field>
                </React.UI.CellGroup>
                <div style={{ margin: '16px' }}>
                    <React.UI.Button round block color="linear-gradient(to right, #ff6034, #ee0a24)" nativeType="submit">
                        提交
                    </React.UI.Button>
                </div>
            </React.UI.Form>
        </>
    );
};

export default EmailVerification;
