import React from "react";
import Home from "@/components/home";
import AuthRouter from './auth'

// 引入当前目录下面的所有文件
const ModulesFile = require.context("./", true, /.js$/);

const RouterMap = [];

ModulesFile.keys().reduce((modules, modulePath) => {
    // 路由模块名称
    const ModuleName = modulePath.replace(/^.\/(.*)\.js/, "$1");

    //不包含当前index.js 文件
    if (ModuleName !== "index" &&  ModuleName !== 'auth') {

        //路由列表(数组)
        const ModuleList = ModulesFile(modulePath);

        //追加到数组
        RouterMap.push(...ModuleList.default);
    }

    return RouterMap;
}, {});

const RouterList = () => {
    return (
        <>
            <React.Router.Routes>
                <React.Router.Route path="/" element={<Home />} />
                <React.Router.Route
                    path="/" element={ <><React.Router.Outlet /></>}
                >
                    {
                        RouterMap.map((item, index) =>{
                            return(
                                <React.Router.Route path={item.path} element={<AuthRouter auth={item.auth} component={<item.component />}></AuthRouter>} key={index}>
                                    {
                                        item.children && item.children.map((child, chilindex) =>{
                                            return(
                                                <React.Router.Route path={child.path} element={<AuthRouter auth={child.auth} component={<child.component />}></AuthRouter>} key={chilindex}/>
                                            )
                                        })
                                    }
                                </React.Router.Route>
                            )
                        })
                    }
                </React.Router.Route>
            </React.Router.Routes>
        </>
    );
};

export default RouterList;
