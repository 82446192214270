// 引入当前目录下面的所有文件
const ModulesFile = require.context("./", true, /.js$/);

// 接口集合
const ApiList = {};

ModulesFile.keys().reduce((modules, modulePath) => {
    // 路由模块名称
    const ModuleName = modulePath.replace(/^.\/(.*)\.js/, "$1");

    //不包含当前index.js 文件
    if (ModuleName !== "index") {
        //路由列表(数组)
        const ModuleList = ModulesFile(modulePath);

        //合并对象 循环多次合并
        Object.assign(ApiList, ModuleList.default);
    }

    return ApiList;
}, {});

//导出接口集合
export default ApiList;