import React from "react";
import GuestIndex from "@/components/guest/index";
import GuestAdd from '@/components/guest/add'
import GuestEdit from '@/components/guest/edit'

const RouterMap = [
    {
        path: "index",
        component: GuestIndex,
        auth: true
    },
    {
        path: "add",
        component: GuestAdd,
        auth: true
    },
    {
        path: "edit",
        component: GuestEdit,
        auth: true
    },
];

//默认父组件 可以自己定义
const layout = () => {
    return (
        <>
            <React.Router.Outlet />
        </>
    );
};

const RouterList = [
    {
        path: "/guest",
        component: layout,
        children: RouterMap,
    },
];

export default RouterList;