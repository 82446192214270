import { POST, } from "@/services/request.js";
const base = {
    hotelIndex(data = {}) {
        return POST({
            url: "/hotel/index",
            params: data,
        })
    },
    hotelInfo(data = {}) {
        return POST({
            url: "/hotel/info",
            params: data,
        })
    },
}
export default base;