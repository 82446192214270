import { POST, } from "@/services/request.js";
//导出接口
const base = {
    couponIndex(data = {}) {
        return POST({
            url: "/coupon/index",
            params: data,
        })
    },
    couponInfo(data = {}) {
        return POST({
            url: "/coupon/info",
            params: data,
        })
    },
    couponReceive(data = {}) {
        return POST({
            url: "/coupon/receive",
            params: data,
        })
    },
    couponBusiness(data = {}) {
        return POST({
            url: "/coupon/business",
            params: data,
        })
    },
}
export default base;
