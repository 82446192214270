import { POST, } from "@/services/request.js";
const base = {
    orderAdd(data = {}) {
        return POST({
            url: "/order/add",
            params: data,
        })
    },
    orderIndex(data = {}) {
        return POST({
            url: "/order/index",
            params: data,
        })
    },
    orderInfo(data = {}) {
        return POST({
            url: "/order/info",
            params: data,
        })
    },
    orderRate(data = {}) {
        return POST({
            url: "/order/rate",
            params: data,
        })
    },
    orderRefund(data = {}) {
        return POST({
            url: "/order/refund",
            params: data,
        })
    },
}
export default base;