import React from "react";

const GuestIndex = () => {

    let navigate = React.Router.useNavigate();
    const [business, setBusiness] = React.useState(
        React.cookie.load("business") ? React.cookie.load("business") : {}
    );
    const [finished, setFinished] = React.useState(false);
    const [page, setPage] = React.useState(1);
    const [list, setList] = React.useState([]);


    const handleNavigation = () => {
        navigate('/guest/add');
    };
    const onRefresh = () => {
        setFinished(false);
        setPage(1);
        setList([]);
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve(true);
            }, 1000);
        });
    };

    const onLoadRefresh = () => {
        setFinished(true);
        GetGuestData();
    };

    const GetGuestData = async () => {
        let result = await React.Api.guestIndex({
            page: page,
            busid: business.id,
        });

        if (result) {
            if (result.code == 0) {
                setFinished(true);
                React.info(result.msg)
                return false;
            } else {
                setPage(page + 1)
                setList(list.concat(result.data))
                setTimeout(() => {
                    setFinished(false);
                }, 2000);
            }
        }
    };
 // 删除
 const DelGuesinfo = (item) => {
    console.log(item)
    React.UI.Dialog.confirm({
        message: `确认是否删除${item.nickname}?`,
        onConfirm: async () => {
            let result = await React.Api.guestDel({
                guestid: item.id,
                busid: business.id,
            });

            if (result && result.code == 1) {
                React.success(result.msg)
                GetGuestData();
            } else {
                React.error(result.msg);
            }
        },
    }).catch(() => { });
}
// 编辑
const EditGuesinfo = (id) => {
    navigate(`/guest/edit?guestid=${id}`)
}

    return (
        <>
            <React.UI.NavBar
                title="住客信息"
                leftText=""
                onClickLeft={() => navigate("/business/index")}
            />

    {/* 列表 */}
    <React.UI.PullRefresh
                successText="刷新成功"
                onRefresh={() => onRefresh(true)}
                style={{ height: "85vh", overflow: "auto" }}
                onRefreshEnd={() => console.log('onRefreshEnd')}
            >
                {/* List 组件可以与 PullRefresh 组件结合使用，实现下拉刷新的效果 */}
                <React.UI.List finished={finished} onLoad={onLoadRefresh}>
                    {
                        list.map((item) => {
                            return (
                                <React.UI.SwipeCell
                                    key={item.id}
                                    rightAction={
                                        <>
                                            <React.UI.Button square type="warning" onClick={() => EditGuesinfo(item.id)}>
                                                编辑
                                            </React.UI.Button>
                                            <React.UI.Button square type="danger" onClick={() => DelGuesinfo(item)}>
                                                删除
                                            </React.UI.Button>
                                        </>
                                    }
                                >

                                    <React.UI.Cell isLink>
                                        <div>{item.nickname} ({item.gender == '0' ? '男' : '女' })</div>
                                        <div style={{color: 'rgb(150, 151, 153)'}}>{item.mobile}</div>
                                    </React.UI.Cell>
                                </React.UI.SwipeCell>
                            )
                        })
                    }

                </React.UI.List>
            </React.UI.PullRefresh>
            <React.UI.Button type="primary" block onClick={handleNavigation}>
            添加住客信息
        </React.UI.Button>
       
        </>
    );
};

export default GuestIndex;
