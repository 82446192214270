import React from "react";
const OrderIndex = () => {
    let navigate = React.Router.useNavigate();
    const [business, setBusiness] = React.useState(
        React.cookie.load("business") ? React.cookie.load("business") : {}
    );
    // 订单状态
    const statuslist = [
        {
            id: '0',
            name: '全部'
        },
        {
            id: '1',
            name: '已支付'
        },
        {
            id: '2',
            name: '已入住'
        },
        {
            id: '3',
            name: '已退房'
        },
        {
            id: '4',
            name: '已完成'
        },
        {
            id: '-1',
            name: '售后'
        }
    ]
    const [status, setStatus] = React.useState('0')
    const [list, setList] = React.useState([])

    React.useEffect(() => {
        getOrderData()
    }, [status])

    const getOrderData = async () => {
        let result = await React.Api.orderIndex({
            busid: business.id,
            status: status
        });

        if (result && result.code == 1) {
            setList(result.data)
        }
    }

    const refundEvent = (id) => {
        React.UI.Dialog.confirm({
            message: "确认是否申请退款?",
            onConfirm: async () => {
                let result = await React.Api.orderRefund({
                    busid: business.id,
                    orderid: id,
                });
                if (result.code == 1) {
                    getOrderData()
                    React.success(result.msg);
                } else {
                    React.error(result.msg);
                }
            },
        }).catch(() => { });
    }

    const Btns = ({ item }) => {
        if (item.status == '3' && !item.rate) {
            return (
                <React.UI.Button type='info' round size='small' onClick={() => navigate(`/order/rate?orderid=${item.id}`)}>立即评价</React.UI.Button>
            )
        } else if (item.status > 0 && item.status != 4) {
            return (
                <React.UI.Button type='danger' round size='small' onClick={() => refundEvent(item.id)}>申请退款</React.UI.Button>
            )
        }
    }

    return (
        <>
            <link rel="stylesheet" href="/assets/css/order.css" />
            <React.UI.NavBar
                title="房间订单"
                leftText=""
                onClickLeft={() => navigate('/business/index')}
            />

            <React.UI.Tabs sticky swipeable active={status} onClickTab={(val) => setStatus(val.name)}>
                {statuslist.map(item => (
                    <React.UI.Tabs.TabPane key={item.id} title={item.name} name={item.id}></React.UI.Tabs.TabPane>
                ))}
            </React.UI.Tabs>

            <div className="order_list">
                {
                    list.map((item) => {
                        return (
                            <div className="item" key={item.id}>
                                <div className="item_top">
                                    <p>{item.room.name}</p>
                                    <div className="top_tag">{item.status_text}</div>
                                </div>
                                <div className="house">
                                    <div className="item_swipers">
                                        <React.Router.NavLink to={`/order/info?orderid=${item.id}`} >
                                            <img src={item.room.thumb_text} alt="" />
                                        </React.Router.NavLink>
                                    </div>
                                    <div className="item_times">
                                        <div>
                                            <div>{item.starttime_text}</div>
                                            <div>{item.startday_text}</div>
                                        </div>
                                        <div>
                                            <div>共{item.order_day}晚</div>
                                            <div className="item_right">
                                                <img src="/assets/images/right1.png" alt="" />
                                            </div>
                                        </div>
                                        <div>
                                            <div>{item.endtime_text}</div>
                                            <div>{item.endday_text}</div>
                                        </div>
                                        <div>
                                            <div className="item_pay">订单总价</div>
                                            <div className="item_price">￥{item.price}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="item_bar">
                                    <React.UI.Button type='warning' round size='small' onClick={() => navigate(`/order/info?orderid=${item.id}`)}>订单详情</React.UI.Button>

                                    <Btns item={item} />
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </>
    )
}

export default OrderIndex;