//引入封装的请求服务
import { POST, UPLOAD } from "@/services/request.js";

//导出接口
const base = {
    register(data = {}) {
        return POST({
            url: "/business/register",
            params: data,
        });
    },
    login(data = {}) {
        return POST({
            url: "/business/login",
            params: data,
        });
    },
    check(data = {}) {
        return POST({
            url: "/business/check",
            params: data,
        });
    },
    profile(data = {}) {
        return UPLOAD({
            url: "/business/profile",
            params: data,
        });
    },
    email(data = {}) {
        return UPLOAD({
            url: "/business/email",
            params: data,
        });
    },
    sendemail(data = {}){
        return POST({
            url: 'business/sendemail',
            params: data
        })
    },
    hotelsIndex(data = {}){
        return POST({
            url: 'business/index',
            params: data
        })
    },
};

export default base;
