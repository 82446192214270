import React from "react";
const Login = () => {
    const [form] = React.UI.Form.useForm();
    let navigate = React.Router.useNavigate();
    const onFinish = async (values) => {
        let result = await React.Api.login(values);
        if (result.code == 1) {
            React.cookie.save('business', result.data)
            React.success(result.msg, () => navigate("/business/index"));
        } else {
            React.error(result.msg);
        }
    };
    return (
        <>
            <link rel="stylesheet" href="/assets/css/login.css" />
            <div className="login">
                <React.UI.Form
                    layout="vertical"
                    form={form}
                    onFinish={onFinish}
                    footer={
                        <div style={{ margin: "16px 16px 0" }}>
                            <React.UI.Button
                                round
                                nativeType="submit"
                                color="linear-gradient(to right, #ff6034, #ee0a24)"
                                block
                            >
                                登陆
                            </React.UI.Button>
                        </div>
                    }
                >
                    <React.UI.Form.Item
                        rules={[
                            { required: true, message: "请填写手机号码" },
                            { pattern: /^1[356789]\d{9}$/, message: "请填写正确的手机号码" },
                        ]}
                        name="mobile"
                        label="手机号码"
                        initialValue="13060808099"
                    >
                        <React.UI.Input placeholder="请输入手机号码" />
                    </React.UI.Form.Item>
                    <React.UI.Form.Item
                        rules={[{ required: true, message: "请填写密码" }]}
                        name="password"
                        label="密码"
                        initialValue="root"
                    >
                        <React.UI.Input placeholder="请输入密码" type="password" />
                    </React.UI.Form.Item>
                </React.UI.Form>

                <div className="tips">
                    <div></div>
                    <div>
                        <React.Router.NavLink to="/business/register">
                            注册
                        </React.Router.NavLink>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Login;
