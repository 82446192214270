import React from "react";
const GuestAdd = () => {
    let navigate = React.Router.useNavigate();
    const [form] = React.UI.Form.useForm()
    const [business, setBusiness] = React.useState(
        React.cookie.load("business") ? React.cookie.load("business") : {}
    );
    const onFinish = async (values) => {
        values.busid = business.id
        let result = await React.Api.guestAdd(values);
        if (result && result.code == 1) {
            React.success(result.msg, () => navigate("/guest/index"));
        } else {
            React.error(result.msg);
        }
    }
    return (
        <>
            <React.UI.NavBar
                title="添加住客信息"
                leftText=""
                onClickLeft={() => navigate(-1)}
            />

            {/* 表单 */}
            <React.UI.Form
                form={form}
                onFinish={onFinish}
                footer={
                    <div style={{ margin: '16px 16px 0' }}>
                        <React.UI.Button round nativeType='submit' type='info' block>
                            提交
                        </React.UI.Button>
                    </div>
                }
            >
                <React.UI.Form.Item
                    rules={[{ required: true, message: '请填写昵称' }]}
                    name='nickname'
                    label='昵称'
                >
                    <React.UI.Input placeholder='请输入昵称' />
                </React.UI.Form.Item>
                <React.UI.Form.Item
                    rules={[{ required: true, message: '请填写手机号码' }]}
                    name='mobile'
                    label='手机号码'
                >
                    <React.UI.Input placeholder='请输入手机号码' />
                </React.UI.Form.Item>
                <React.UI.Form.Item name='gender' label='性别' rules={[{ required: true, message: '请选择性别' }]}>
                    <React.UI.Radio.Group direction='horizontal'>
                        <React.UI.Radio name='0'>男</React.UI.Radio>
                        <React.UI.Radio name='1'>女</React.UI.Radio>
                    </React.UI.Radio.Group>
                </React.UI.Form.Item>
            </React.UI.Form>
        </>
    )
}

export default GuestAdd;