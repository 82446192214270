import React from "react";
const Footer = () => {
    let navigate = React.Router.useNavigate();
    let location = React.Router.useLocation();
    const [name, setName] = React.useState('/')

    React.useEffect(() =>{
        setName(location.pathname)
    }, [])

    const ChnageTabbar = (val) =>{
        setName(val)
        navigate(val)
    }

    return (
        <>
            <React.UI.Tabbar value={name} onChange={v => ChnageTabbar(v)} activeColor='#f44336' inactiveColor='#000'>
                <React.UI.Tabbar.Item name='/' icon={<React.ICON.HomeO />}>首页</React.UI.Tabbar.Item>
                <React.UI.Tabbar.Item name='/business/index' icon={<React.ICON.FriendsO />}>我的</React.UI.Tabbar.Item>
            </React.UI.Tabbar>
        </>
    );
};

export default Footer;