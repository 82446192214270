import React from "react";
// UI
import * as UI from "react-vant";

// 图标
import * as ICON from "@react-vant/icons";

// 路由
import * as Router from "react-router-dom";

//接口
import Api from "@/api/index.js";

// cookie
import * as cookie from 'react-cookies'


// 在全局React身上做一个自定义属性的设置
React.UI = UI;
React.ICON = ICON;
React.Router = Router;
React.Api = Api;
React.cookie = cookie;

React.success = (msg, callback) => {
    React.UI.Toast.success({
        message: msg,
        duration: 1500,
        onClose: callback  ? callback : () => {}
    });
};

// 失败提醒方法
React.error = (msg, callback) => {
    React.UI.Toast.fail({
        message: msg,
        duration: 1500,
        onClose: callback  ? callback : () => {}
    });
};

// 提示
React.info = (msg) => {
    React.UI.Toast.info({
        message: msg,
        duration: 1500,
        position: 'bottom'
    });
};
